import React, { useContext } from 'react';
import { MenuContext } from '../../../context'

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data'

const Article = () => {
  const menuContext = useContext(MenuContext);

  return (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Thursday 6th May 2021, we will be making the following changes to our mortgage product range:</p>
      <p><strong>Summary of changes</strong></p>
      <ul>
        <li>2 Year Fixed Standard at 70%, 75%, 80%, 90% and 95% (MGS) LTV have reduced</li>
        <li>2 Year Fixed Fee Saver at 60%, 80%, 90% and 95% (MGS) LTV have reduced</li>
        <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 95% (MGS) LTV have reduced</li>
        <li>5 Year Fixed Fee Saver at 60%, 80%, 85% and 95% (MGS) LTV have reduced</li>
        <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75% and 80% LTV have reduced</li>
      </ul>
      <p>We have reintroduced our 2 and 5 Year Fixed Standard products within our 95% LTV range for existing customers switching rates only. Please note that these products are for use outside of the Government backed Mortgage Guarantee Scheme (MGS) and these rates should be selected from the 95% LTV range found within the Mortgage Rate Sheet clearly labelled as 'Rate Switch Only'.</p>
      <p>An updated mortgage rate sheet will be published on Thursday 6th May 2021 to reflect these changes.</p>
      <p>You can browse the range of HSBC mortgages using our <a href="/products/product-finder.html" inert={menuContext.isMenuOpen ? "true" : null}>product finder</a> or by downloading our <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href} inert={menuContext.isMenuOpen ? "true" : null}>latest rate sheet<span className="accessible">&nbsp;This PDF opens in a new window</span></a>.</p>
    </NewsArticle>
  )
}

export default Article
